<template lang="pug">
div#exam
  div.navs.flex.txt.red.small.padding._10_0
    router-link.grow.center(:to="`/wocanyu/wolai${n.ico}`" :class="{active: tab.i == i}" v-for="(n, i) in tab.items" :key="i")
      img.center(:src="require(`@/assets/img/${i == tab.i?'_':''}${n.ico}.png`)")/
      h2 {{n.tit}}
  router-view/
</template>
<script>
export default {
  data() {return{
    tab: {items: [{tit: '我来战', ico: 'zhan'},{tit: '我来配', ico: 'pei'},{tit: '我来种', ico: 'zhong'}], i: 0}
  }},
  watch:{
    $route(to){
      this.tab.i = {wolaizhan: 0, wolaipeiList: 1, wolaipeiId: 1, wolaizhong: 2}[to.name] ?? 0
    }
  }
}
</script>
<style lang="less">
@import "~@/assets/less/func.less";
#exam{
  .navs {
    &, h2{.__rem(5, margin-top);}
    img{ .__wh(40, 35.3); }
  }
  .banner img{ width: 100%;}
  .icon-small{.__icon(10, 2);}
  .icon-noraml{.__icon(15, 4);}
  .blue_red_line{
    .__position();
    .__rem(4, height);
    .__bg(var(--red););
    .__border(bottom, #ccc, solid, 5);
    &::before{
      content: "";
      width: 40%;
      .__rem(4, height);
      .__position(2);
      .__bg(var(--blue););
    }
  }
  
  .exam{
    .margin{.__spacing(margin, 20, 0);}
    .__border(bottom, #ddd);
    .id{.__rem(15, margin-left); .__rem(5, margin-right);}
    .icon-noraml{.__spacing(margin, 0, 5);}
    .icon-noraml.uncheck{
      &::before,&::after{background-color: #ddd;}
    }
  }
  .result{
    line-height: 3;
    .a{text-decoration: underline;}
  }
}
</style>